













































































import Vue, { PropType } from "vue";
import { FileUploader } from "@/interfaces/shared/file_validation";
import { get_file_size, validate_file_type } from "@/utils/global";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  name: "FileUploader",
  props: {
    // Card description
    description: {
      type: String as PropType<TranslateResult>,
      required: true
    },
    // Maximum file size to allowed
    // default: 20MB
    max_file_size: {
      type: Number,
      default: 2e7
    },
    // Maximum file size text to display in case of error
    // Default: 20MB
    max_file_size_string: {
      type: String,
      default: "20"
    },
    // File type to validate
    // Default: file => pdf, word, text
    file_type: {
      type: String,
      default: "file"
    },
    // Accept attribute of v-file-input
    allowed_file_types: {
      type: String,
      required: true
    },
    // Allowed multiple file picking
    // Default: false
    // Implement pending
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data(): FileUploader {
    return {
      file: null,
      show_error_alert: false,
      error_msg: "",
      selected_file: null
    };
  },
  methods: {
    get_file_size,
    /**
     * Validate user selected file through drag & drop
     * @param {DragEvent} value
     */ process_dropped_resume(value: DragEvent) {
      if (value && value.dataTransfer) {
        // If user are allowed to pick only 1 file
        if (!this.multiple) {
          this.file = value.dataTransfer.files[0];
          this.validate_file(this.file);
        }
      }
    },
    /**
     * Validate user selected file through file uploader
     * @param {File} file
     */
    process_selected_resume(file: File) {
      if (!this.multiple) {
        this.file = file;
        this.validate_file(this.file);
      }
    },
    /**
     * Validate user selected file and set selected_file if valid
     * @param {File} file
     */
    validate_file(file: File) {
      if (!this.multiple) {
        const valid_file = this.validate_file_type(file);
        // If file not valid display error alert
        if (!valid_file.valid) {
          this.error_msg = valid_file.msg;
          this.show_error_alert = true;
        } else {
          this.selected_file = this.file;
          this.error_msg = valid_file.msg;
          this.show_error_alert = false;
          this.$emit("output", this.selected_file);
        }
      }
    },
    /**
     * Validate file size & file type
     * @param file
     */
    validate_file_type(file: File) {
      if (file.size > this.max_file_size) {
        return {
          valid: false,
          msg: `File size should be less than ${this.max_file_size_string} MB!`
        };
      } else if (this.file_type === "file") {
        if (
          validate_file_type("pdf", file) ||
          validate_file_type("word", file) ||
          validate_file_type("text", file)
        ) {
          return {
            valid: true,
            msg: ""
          };
        } else {
          return {
            valid: false,
            msg: "Unsupported file type. .pdf, .docx, .txt files are allowed"
          };
        }
      } else {
        if (validate_file_type("png", file)) {
          return {
            valid: true,
            msg: ""
          };
        } else {
          return {
            valid: false,
            msg: "Unsupported file type. .png, .svg, .jpeg files are allowed"
          };
        }
      }
    },
    /**
     * Function to unselect selected file
     */
    remove_selected_file() {
      this.selected_file = null;
      this.file = null;
      this.error_msg = "";
      this.show_error_alert = false;
      this.$emit("output", null);
    }
  }
});
