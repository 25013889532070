






















































































































































import Vue, { PropType } from "vue";
import {
  InstituteType,
  SelectDegree,
  SelectDegreeDocType
} from "@/interfaces/modules/institute/syllabi_comparison";
import { TranslateResult } from "vue-i18n";
import FileUploader from "@/components/shared/FileUploader.vue";
import { clean_string, get_allowed_file_types } from "@/utils/global";
import { mapActions, mapGetters } from "vuex";
import {
  ALL_INSTITUTES,
  FETCH_INSTITUTE_PROGRAM,
  FETCH_INSTITUTES,
  GLOBAL_INSTITUTES,
  INSTITUTE_STATE_LOADING,
  LOCAL_INSTITUTES
} from "@/store/modules/institute/constants";
import { Institutes } from "@/interfaces/data_objects/institutes";
import { Programs } from "@/interfaces/data_objects/programs";

export default Vue.extend({
  name: "SelectDegree",
  components: { FileUploader },
  data(): SelectDegree {
    return {
      doc_type: SelectDegreeDocType.FILE,
      paste_content_rules: [
        (value: string) => !!value.trim() || this.$t("rules.required"),
        (value: string) => value.trim().length > 70 || this.$t("rules.min-text")
      ],
      search_institute: "",
      search_program: "",
      searched_institutes: [],
      searched_programs: [],
      selected_institute: null,
      selected_program: null,
      all_institute_programs: [],
      selected_degree_file: null,
      selected_degree_content: ""
    };
  },
  computed: {
    SelectDegreeDocType() {
      return SelectDegreeDocType;
    },
    InstituteType() {
      return InstituteType;
    },
    ...mapGetters("institute", {
      institute_state_loading: INSTITUTE_STATE_LOADING,
      get_all_institutes: ALL_INSTITUTES,
      get_local_institutes: LOCAL_INSTITUTES,
      get_global_institutes: GLOBAL_INSTITUTES
    })
  },
  watch: {
    /**
     * Water to watch each input in autocomplete component
     * @param value
     */
    search_institute(value: string | null) {
      if (this.institute_type === InstituteType.NATIONAL) {
        if (!value) this.searched_institutes = this.get_local_institutes;
        else {
          this.searched_institutes = this.get_local_institutes.filter(
            (institute: Institutes) => {
              if (
                clean_string(institute.institute_name).includes(
                  clean_string(value)
                )
              )
                return institute;
            }
          );
        }
      } else if (this.institute_type === InstituteType.GLOBAL) {
        if (!value) this.searched_institutes = this.get_global_institutes;
        else {
          this.searched_institutes = this.get_global_institutes.filter(
            (institute: Institutes) => {
              if (
                clean_string(institute.institute_name).includes(
                  clean_string(value)
                )
              )
                return institute;
            }
          );
        }
      }
    },
    async search_program(value: string | null) {
      // If program field cleared
      if (!value) {
        // If institute is selected => Fetching program
        if (this.selected_institute) {
          this.searched_programs = this.all_institute_programs;
        }
      } else {
        this.searched_programs = this.all_institute_programs.filter(
          (program: Programs) => {
            if (
              clean_string(program.program_name).includes(clean_string(value))
            )
              return program;
          }
        );
      }
    }
  },
  props: {
    institute_type: {
      type: Number as PropType<InstituteType>,
      required: true
    },
    title: {
      type: String as PropType<TranslateResult>,
      required: true
    },
    form: {
      type: Object
    }
  },
  async mounted() {
    if (this.get_all_institutes.length > 0) {
      if (this.institute_type === InstituteType.NATIONAL) {
        this.searched_institutes = this.get_local_institutes;
      } else this.searched_institutes = this.get_global_institutes;
    }
  },
  methods: {
    ...mapActions("institute", {
      fetch_all_institutes: FETCH_INSTITUTES,
      fetch_institute_program: FETCH_INSTITUTE_PROGRAM
    }),
    get_allowed_file_types,
    process_uploaded_files(file: File | null) {
      this.selected_degree_file = file;
      if (this.selected_degree_file) this.reset_program_data();
      this.$emit("selected_degree_file", file);
    },

    async process_selected_degree_content(value: string) {
      this.$emit("selected_degree_content", value); // Pass to parent
      if (value) this.reset_program_data();
    },
    update_doc_type(type: SelectDegreeDocType) {
      this.doc_type = type;
    },
    /**
     * Function to process selected institute
     * Fetching institute programs
     * @param value
     */
    async process_selected_institute(value: Institutes | null) {
      this.$emit("selected_institute", value); // Pass to parent
      // If institute selected => fetch programs
      if (value) {
        this.all_institute_programs = await this.fetch_institute_program(
          value.id
        );
        this.searched_programs = this.all_institute_programs;
      }
      // Else reset program related data
      else this.reset_program_data();
    },
    /**
     * Function to process selected program
     * @param value
     */
    process_selected_program(value: Programs | null) {
      this.$emit("selected_program", value); // Pass to parent
    },
    reset_program_data() {
      this.searched_programs = [];
      this.search_program = "";
      this.selected_program = null;
      this.$emit("selected_program", null);
    },
    reset_institute_data() {
      this.searched_institutes = [];
      this.search_institute = "";
      this.selected_institute = null;
      this.$emit("selected_institute", null);
    }
  }
});
