var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base-card select-degree d-flex flex-column"},[_c('v-row',[_c('v-col',[_c('p',{staticClass:"mb-0 base-section-sub-title"},[_vm._v(" "+_vm._s(_vm.title)+" ")])])],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"form-field"},[_c('v-autocomplete',{staticClass:"form-field-input",attrs:{"return-object":"","hide-details":"","items":_vm.searched_institutes,"loading":_vm.institute_state_loading,"search-input":_vm.search_institute,"solo":"","item-text":"institute_name","item-value":"id","clear-icon":"mdi-close","clearable":"","no-filter":"","placeholder":_vm.institute_type === _vm.InstituteType.NATIONAL
              ? _vm.$t('institute.syllabi-comparison.select-national')
              : _vm.$t('institute.syllabi-comparison.select-global')},on:{"update:searchInput":function($event){_vm.search_institute=$event},"update:search-input":function($event){_vm.search_institute=$event},"change":_vm.process_selected_institute},scopedSlots:_vm._u([{key:"item",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-img',{attrs:{"src":item.institute_logo,"alt":item.institute_name}}),_c('p',{staticClass:"mb-0 ms-2 base-body-text"},[_vm._v(" "+_vm._s(item.institute_name)+" ")])],1)]}},{key:"selection",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-img',{attrs:{"src":item.institute_logo,"alt":item.institute_name}}),_c('p',{staticClass:"mb-0 ms-4 base-body-text"},[_vm._v(" "+_vm._s(item.institute_name)+" ")])],1)]}}]),model:{value:(_vm.selected_institute),callback:function ($$v) {_vm.selected_institute=$$v},expression:"selected_institute"}})],1)])],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"form-field"},[_c('v-autocomplete',{staticClass:"form-field-input",attrs:{"return-object":"","hide-details":"","solo":"","clear-icon":"mdi-close","clearable":"","no-filter":"","item-text":"program_name","item-value":"id","disabled":!_vm.selected_institute ||
            !!_vm.selected_degree_file ||
            !!_vm.selected_degree_content,"placeholder":_vm.$t('institute.syllabi-comparison.select-program'),"items":_vm.searched_programs,"loading":_vm.institute_state_loading,"search-input":_vm.search_program},on:{"update:searchInput":function($event){_vm.search_program=$event},"update:search-input":function($event){_vm.search_program=$event},"change":_vm.process_selected_program},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('p',{staticClass:"mb-0 base-body-text"},[_vm._v(" "+_vm._s(item.program_name)+" ")])]}},{key:"selection",fn:function(ref){
            var item = ref.item;
return [_c('p',{staticClass:"mb-0 base-body-text"},[_vm._v(" "+_vm._s(item.program_name)+" ")])]}}]),model:{value:(_vm.selected_program),callback:function ($$v) {_vm.selected_program=$$v},expression:"selected_program"}})],1)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"base-submit-button upload-text-btn",class:{ active: _vm.doc_type === _vm.SelectDegreeDocType.FILE },attrs:{"disabled":!!_vm.selected_degree_content},on:{"click":function($event){return _vm.update_doc_type(_vm.SelectDegreeDocType.FILE)}}},[_vm._v(" "+_vm._s(_vm.$t("institute.syllabi-comparison.upload"))+" ")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"base-submit-button upload-text-btn",class:{ active: _vm.doc_type === _vm.SelectDegreeDocType.TEXT },attrs:{"disabled":!!_vm.selected_degree_file},on:{"click":function($event){return _vm.update_doc_type(_vm.SelectDegreeDocType.TEXT)}}},[_vm._v(" "+_vm._s(_vm.$t("institute.syllabi-comparison.paste"))+" ")])],1)],1),_c('v-row',[_c('v-col',[(_vm.doc_type === _vm.SelectDegreeDocType.FILE)?_c('FileUploader',{attrs:{"description":_vm.$t('institute.syllabi-comparison.allowed-types'),"allowed_file_types":_vm.get_allowed_file_types()},on:{"output":_vm.process_uploaded_files}}):_c('div',{staticClass:"form-field"},[_c('v-textarea',{staticClass:"form-field-input",attrs:{"rows":"6","placeholder":_vm.$t('institute.syllabi-comparison.paste-content-placeholder'),"type":"text","rules":_vm.paste_content_rules,"solo":"","counter":"","hint":_vm.$t('rules.min-text')},on:{"change":_vm.process_selected_degree_content},model:{value:(_vm.selected_degree_content),callback:function ($$v) {_vm.selected_degree_content=$$v},expression:"selected_degree_content"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }